import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Dashboard from '@material-ui/icons/Dashboard';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

import {

  FolderOpen,

} from '@material-ui/icons/';
import { redirecTo } from '../../services/auth';




const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  menuButton: {
    marginRight: 26,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 60,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // width: theme.spacing(0) + 1,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {

    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


function Page(props) {
  const {
    openClose, permisos, handleContrato
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(openClose);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <div className={classes.paddinTop} onMouseEnter={() => handleDrawerOpen()}
        onMouseLeave={() => handleDrawerClose()}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}>
          <div className={classes.drawerHeader}>
          </div>
          <Divider />
          <List>
            {(permisos.tabla || permisos.formulario) && open && <ListItem>
              <Typography variant="subtitle2" >
                Menu
                </Typography>
            </ListItem>
            }
            {permisos.tabla &&
              <ListItem button component={Link} to="/"  >
                <ListItemIcon >
                  <LibraryBooks color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Log MLP" />
              </ListItem>
            }
            {permisos.tabla &&
              <ListItem button component={Link} to="/dashboard"  >
                <ListItemIcon >
                  <Dashboard color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            }

            <ListItem button className={classes.nested} onClick={() => { redirecTo("https://sgi.cydocs.cl/") }}>
              <ListItemIcon >
                <FolderOpen color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Gestor de Información " />
            </ListItem>


          </List>

        </Drawer>
      </div>
    </Fragment>
  );
}

export default Page;