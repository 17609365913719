export const type = 'openModal';

const openModal = payload => ({
    type,
    payload:{
        ...payload,
        id: Math.random().toString(36),
    }
});

export default openModal;