import React, { Component } from 'react';
import Page from './page';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import clickMenuLateral from '../../redux/actions/actionbar/clickMenuLateral'
import { getCurrentUser } from '../../services/auth'
import CircularProgress from '@material-ui/core/CircularProgress';
import openModal from '../../redux/actions/modal/openModal'
import closeModal from '../../redux/actions/modal/closeModal'


class DrawerMain extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            openClose: true,
            rol: null,
            permisos: {
                formulario: true,
                tabla: true
            }
        };
    }


    componentDidMount() {
        this._isMounted = true;

        this._asyncCurrentUser = getCurrentUser().then(
            async  user => {
                if (user.claims.rol) {
                    if (this._isMounted) {
                        this.setState({ rol: user.claims.rol });
                        this.setState({
                            permisos: {
                                formulario: true,
                                tabla: true
                            }
                        });
                    }
                }
            }
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleContrato = e => {
        const { openModal } = this.props
        
    }

    render() {
        var toggle = this.state.openClose ? false : true;
        const { rol, permisos } = this.state;
        if (this.state.rol === null) {
            return (
                <CircularProgress style={{ paddingTop: 15 }} />
            );
        } else {
            return (
                <Page
                    rol={rol}
                    openClose={toggle}
                    permisos={permisos}
                    handleContrato={this.handleContrato}
                />
            );
        }

    }
}



function mapStateToProps(state) {
    return {
    }
}
const mapDispatchToProps = {
    //clickMenuLateral
    openModal,
    closeModal
};

// conectar un componente con redux 
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrawerMain)
);
