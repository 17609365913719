import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';



const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),

    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    }
}));

function Page(props) {
    const classes = style(); 

    return (
        <Fragment>
            <CssBaseline />
            {/* <Drawer ></Drawer> */}

            <main>
                <Container maxWidth="xl" className={classes.main} >
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            App PSI
                        </Link>
                        <Typography color="textPrimary">Dashboard</Typography>
                    </Breadcrumbs>
                    <iframe src="https://datastudio.google.com/embed/reporting/1AJfhNkh-tVEVzLie0kuEdRZXjZmo9dTs/page/3wcBB" frameBorder={0} style={{ border: 0, width: '100%', height: '80vh'}} allowFullScreen></iframe>
                </Container >
            </main>
        </Fragment >
    );
}

export default Page;